<template id="player_data">
  <b-container fluid>
    <b-navbar type="dark" class="p-0" v-if="osservato">
      <b-navbar-nav :class="!auth.isAdmin() ? 'no-admin' : ''">
        <b-nav-item
          v-if="osservato.giocatore.opta_player && auth.isUserEnable('opta')"
          :to="'/scouting/view/player/' + osservato.giocatore.opta_player.id"
        >
          Player card
        </b-nav-item>
        <b-nav-item
          v-if="auth.isUserEnable('player_eval')"
          :to="'/scouting/eval/player/' + osservato.giocatore.id"
        >
          Evaluation
        </b-nav-item>
        <b-nav-item
          v-if="
            osservato.giocatore.wyscout_id &&
            auth.isUserEnable('player_videotag')
          "
          :to="'/scouting/videotag/player/' + osservato.giocatore.id"
        >
          Videotag
        </b-nav-item>
        <b-nav-item
          v-if="osservato.giocatore.opta_player && auth.isUserEnable('opta')"
          :to="
            '/scouting/player/index/games/' + osservato.giocatore.opta_player.id
          "
        >
          Matches
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-card
      :title="this.$route.params.id ? $t('editPlayer') : $t('newPlayer')"
      style="background: transparent; border: none"
    >
      <b-card-text>
        <b-row>
          <b-col>
            <b-form @submit="onSubmit">
              <b-row align-v="center">
                <b-col cols="2">
                  <picture-input
                    ref="picturePlayer"
                    @change="onAvatarChange"
                    width="150"
                    height="150"
                    size="10"
                    class="my-1"
                    :customStrings="customStrings"
                    :prefill="avatar"
                  ></picture-input>
                </b-col>
                <b-col v-if="!form.opta_player_id && form.cognome != ''">
                  <span style="margin-right: 10px"> No Opta Mapping </span>
                  <b-button variant="primary" @click="openSearchOptaPlayer"
                    ><b-icon icon="search"></b-icon
                  ></b-button>
                </b-col>
                <b-col v-if="auth.isAdmin() && form.opta_player_id">
                  <b-img
                    :src="logoopta"
                    height="40"
                    title="Opta stats avalable"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="nomeGroup"
                    horizontal
                    :label="$t('name')"
                    :label-cols="3"
                    label-for="nome"
                    :invalid-feedback="errors.nome ? $t(errors.nome[0]) : ''"
                    :state="errors.nome ? false : null"
                  >
                    <b-form-input
                      id="nome"
                      type="text"
                      v-model="form.nome"
                      :state="errors.nome ? false : null"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="cognomeGroup"
                    horizontal
                    :label="$t('surname')"
                    :label-cols="3"
                    label-for="cognome"
                    :invalid-feedback="
                      errors.cognome ? $t(errors.cognome[0]) : ''
                    "
                    :state="errors.cognome ? false : null"
                  >
                    <b-input-group>
                      <b-form-input
                        id="cognome"
                        type="text"
                        v-model="form.cognome"
                        :state="errors.cognome ? false : null"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          @click="searchTeam"
                          :title="$t('searchByTeam')"
                          ><b-icon icon="people-fill"></b-icon
                        ></b-button>
                        <b-button
                          variant="primary"
                          @click="searchPlayer"
                          :title="$t('searchByName')"
                          ><b-icon icon="search"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    id="dataDiNascitaGroup"
                    horizontal
                    :label="$t('dateOfBirth')"
                    :label-cols="3"
                    label-for="data_nascita"
                  >
                    <b-form-input
                      id="data_nascita"
                      type="date"
                      v-model="form.data_nascita"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="paeseNascitaGroup"
                    horizontal
                    :label="$t('countryOfBirth')"
                    :label-cols="3"
                    label-for="paese_nascita"
                  >
                    <typeahead-country
                      :suggestions="paesi"
                      id="paese_nascita"
                      ref="paese_nascita"
                      @select="onPaeseNascitaSelect"
                      @change="onPaeseNascitaChange"
                      v-model="form.paese_nascita"
                    ></typeahead-country>
                  </b-form-group>
                  <b-form-group
                    id="passaportiGroup"
                    horizontal
                    :label="$t('passports')"
                    :label-cols="3"
                  >
                    <b-row v-if="auth.isAdmin()">
                      <b-col>
                        <b-button variant="primary" @click="addPassaporto"
                          ><b-icon icon="plus"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(item, index) in form.passaporti"
                      class="my-1"
                      :key="item.id"
                    >
                      <b-col>
                        <typeahead-country
                          :suggestions="paesi"
                          @select="onPassaportoSelect"
                          @change="onPassaportoChange"
                          v-model="item.paese_id"
                          :row_index="index"
                          ref="passaporto"
                        ></typeahead-country>
                      </b-col>
                      <b-col cols="2" v-if="auth.isAdmin()">
                        <b-button
                          variant="primary"
                          @click="removePassaporto(item)"
                          ><b-icon icon="dash"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="altezzaGroup"
                    horizontal
                    label="Height (cm)"
                    :label-cols="3"
                    label-for="altezza"
                  >
                    <b-form-input
                      id="altezza"
                      type="text"
                      v-model="form.altezza"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="pesoGroup"
                    horizontal
                    label="Weight (kg)"
                    :label-cols="3"
                    label-for="peso"
                  >
                    <b-form-input
                      id="peso"
                      type="text"
                      v-model="form.peso"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="piedeGroup"
                    horizontal
                    label="Foot"
                    :label-cols="3"
                    label-for="piede"
                  >
                    <b-form-radio-group id="piede" v-model="form.piede">
                      <b-form-radio value="0">Right</b-form-radio>
                      <b-form-radio value="1">Left</b-form-radio>
                      <b-form-radio value="2">Ambidextrous</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    id="squadraGroup"
                    horizontal
                    label="Team"
                    :label-cols="3"
                    label-for="squadra"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-img :src="logo_squadra" width="24" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="squadra"
                        type="text"
                        v-model="form.squadra"
                        style="font-size: 1rem"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="loadSquadra"
                          title="Load team from archive"
                          ><b-icon icon="folder-fill"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="categoriaGroup"
                    horizontal
                    label="Category"
                    :label-cols="3"
                    label-for="categoria"
                  >
                    <b-form-input
                      id="categoria"
                      type="text"
                      v-model="form.categoria"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="agenteGroup"
                    horizontal
                    label="Agent"
                    :label-cols="3"
                    label-for="agente"
                  >
                    <b-row>
                      <b-col>
                        <typeahead-person
                          :suggestions="agenti"
                          id="agente"
                          @select="onAgenteSelect"
                          @change="onAgenteChange"
                          v-model="form.agente.id"
                          cols="10"
                          :placeholder="$t('surname')"
                          field="cognome"
                          ref="agente"
                        ></typeahead-person>
                      </b-col>
                      <b-col>
                        <b-form-input
                          type="text"
                          v-model="form.agente.nome"
                          :placeholder="$t('name')"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          variant="primary"
                          v-b-toggle.agente_pnl
                          title="+ info"
                          ><b-icon icon="info-fill"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-collapse
                    id="agente_pnl"
                    class="mt-2"
                    @shown="updateImageAgente"
                  >
                    <b-card class="p-1 mb-1">
                      <picture-input
                        ref="pictureAgente"
                        @change="onAvatarAgenteChange"
                        width="150"
                        height="150"
                        size="10"
                        class="mb-1"
                        :customStrings="customStrings"
                        :prefill="avatar_agente"
                      ></picture-input>
                      <b-form-group
                        horizontal
                        label="Nationality"
                        :label-cols="3"
                      >
                        <typeahead-country
                          :suggestions="paesi"
                          id="paese_agente"
                          ref="paese_agente"
                          @select="onPaeseAgenteSelect"
                          @change="onPaeseAgenteChange"
                          v-model="form.agente.id_paese"
                        ></typeahead-country>
                      </b-form-group>
                      <b-form-group
                        horizontal
                        :label="$t('countryOfBirth')"
                        :label-cols="3"
                      >
                        <b-form-input
                          v-model="form.agente.luogo_nascita"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        horizontal
                        :label="$t('dateOfBirth')"
                        :label-cols="3"
                      >
                        <b-form-input
                          type="date"
                          v-model="form.agente.data_nascita"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group horizontal label="Address" :label-cols="3">
                        <b-form-input
                          v-model="form.agente.indirizzo"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="emailAgenteGroup"
                        horizontal
                        label="Email"
                        :label-cols="3"
                        label-for="emailAgente"
                      >
                        <b-form-input
                          id="emailAgente"
                          type="email"
                          v-model="form.agente.email"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="telefono1AgenteGroup"
                        horizontal
                        label="Phone 1"
                        :label-cols="3"
                        label-for="telefono1Agente"
                      >
                        <b-form-input
                          id="telefono1Agente"
                          type="tel"
                          v-model="form.agente.telefono1"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="telefono2AgenteGroup"
                        horizontal
                        label="Phone 2"
                        :label-cols="3"
                        label-for="telefono2Agente"
                      >
                        <b-form-input
                          id="telefono2Agente"
                          type="tel"
                          v-model="form.agente.telefono2"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="AgenziaGroup"
                        horizontal
                        label="Agency"
                        :label-cols="3"
                        label-for="agenzia"
                      >
                        <typeahead-person
                          :suggestions="agenzie"
                          id="agenzia"
                          @select="onAgenziaSelect"
                          @change="onAgenziaChange"
                          v-model="form.agenzia.id"
                          ref="agenzia"
                        ></typeahead-person>
                      </b-form-group>
                    </b-card>
                  </b-collapse>

                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="valoreGroup"
                    horizontal
                    label="Player value"
                    :label-cols="3"
                    label-for="valore"
                  >
                    <b-form-input
                      v-if="auth.isAdmin()"
                      id="valore"
                      type="text"
                      v-model="form.valore"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="stipendioGroup"
                    horizontal
                    label="Gross salary"
                    :label-cols="3"
                    label-for="stipendio"
                  >
                    <b-form-input
                      id="stipendio"
                      type="text"
                      v-model="form.stipendio"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="bonusGroup"
                    horizontal
                    label="Bonus"
                    :label-cols="3"
                  >
                    <b-row>
                      <b-col>
                        <b-button variant="primary" @click="addBonus"
                          ><b-icon icon="plus"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="item in form.bonus"
                      class="my-1"
                      :key="item.id"
                    >
                      <b-col>
                        <b-form-group
                          id="valoreBonusGroup"
                          horizontal
                          label="Valore"
                          :label-cols="3"
                        >
                          <b-form-input
                            type="text"
                            v-model="item.valore"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="descrizioneBonusGroup"
                          horizontal
                          label="Description"
                          :label-cols="3"
                        >
                          <b-form-input
                            type="text"
                            v-model="item.descrizione"
                          ></b-form-input>
                        </b-form-group>
                        <b-button variant="primary" @click="removeBonus(item)"
                          ><b-icon icon="dash"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="telefono1Group"
                    horizontal
                    label="Phone 1"
                    :label-cols="3"
                    label-for="telefono1"
                  >
                    <b-form-input
                      id="telefono1"
                      type="tel"
                      v-model="form.telefono1"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="telefono2Group"
                    horizontal
                    label="Phone 2"
                    :label-cols="3"
                    label-for="telefono2"
                  >
                    <b-form-input
                      id="telefono2"
                      type="tel"
                      v-model="form.telefono2"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="infortuniGroup"
                    horizontal
                    label="Injuries"
                    :label-cols="3"
                  >
                    <b-row>
                      <b-col>
                        <b-button variant="primary" @click="addInfortunio"
                          ><b-icon icon="plus"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="item in form.infortuni"
                      class="my-1"
                      :key="item.id"
                    >
                      <b-col>
                        <b-form-group
                          id="tipoInfortunioGroup"
                          horizontal
                          label="Type"
                          :label-cols="3"
                        >
                          <b-form-select
                            v-model="item.tipo"
                            :options="infortuni"
                            value-field="id"
                            text-field="nome"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          id="dataInfortunioGroup"
                          horizontal
                          label="Date"
                          :label-cols="3"
                        >
                          <b-form-input
                            type="date"
                            v-model="item.data"
                          ></b-form-input>
                        </b-form-group>
                        <b-button
                          variant="primary"
                          @click="removeInfortunio(item)"
                          ><b-icon icon="dash"></b-icon
                        ></b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="giovaniliVivaioGroup"
                    horizontal
                    :label-cols="3"
                  >
                    <b-form-checkbox
                      id="giovanili"
                      v-model="form.giovanili"
                      inline
                    >
                      Raised in youth
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="vivaio_italiano"
                      v-model="form.vivaio"
                      inline
                    >
                      Raised in an Italian nursery
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="baricentroGroup"
                    horizontal
                    label="Center of gravity"
                    :label-cols="3"
                  >
                    <b-form-select v-model="form.baricentro">
                      <option value="0">Low</option>
                      <option value="1">Medium</option>
                      <option value="2">High</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="somatotipoGroup"
                    horizontal
                    label="Somatotype"
                    :label-cols="3"
                  >
                    <b-form-select v-model="form.somatotipo">
                      <option value="0">Normolineo</option>
                      <option value="1">Brevilineo</option>
                      <option value="2">Long-limbed</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="costituzioneGroup"
                    horizontal
                    label="Physique"
                    :label-cols="3"
                  >
                    <b-form-select v-model="form.costituzione">
                      <option value="0">Slim</option>
                      <option value="1">Medium</option>
                      <option value="2">Robust</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="ruoloIdealeGroup"
                    horizontal
                    label="Ideal role"
                    :label-cols="3"
                  >
                    <b-form-select
                      v-model="form.ruolo_ideale"
                      :options="ruoli"
                      value-field="id"
                      text-field="ruolo"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="sistemaGiocoIdealeGroup"
                    horizontal
                    label="Ideal game system"
                    :label-cols="3"
                  >
                    <b-form-select
                      v-model="form.sistema_ideale"
                      :options="sistemi"
                      value-field="id"
                      text-field="sistema_gioco"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="flagVariGroup"
                    horizontal
                    :label-cols="3"
                    v-if="auth.isAdmin()"
                  >
                    <b-form-checkbox
                      id="monitorare"
                      v-model="form.monitorare"
                      inline
                    >
                      To be monitored
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="prospettiva"
                      v-model="form.prospettiva"
                      inline
                    >
                      Of perspective
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="prestito"
                      v-model="form.prestito"
                      inline
                    >
                      Borrowed
                    </b-form-checkbox>
                    <b-form-checkbox id="segui" v-model="form.segui" inline>
                      Follow
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    v-if="auth.isAdmin()"
                    id="GioSimiliGroup"
                    horizontal
                    label="Similar players"
                    :label-cols="3"
                    label-for="gioSimili"
                  >
                    <b-input-group>
                      <b-form-input
                        type="text"
                        v-model="giocatore_simile"
                        placeholder="Similar player name"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary" @click="searchPlayerSimile"
                          ><b-icon icon="search"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-textarea
                      id="gioSimili"
                      v-model="form.giocatori_simili"
                      placeholder="Similar players"
                      :rows="2"
                      :max-rows="2"
                    >
                    </b-form-textarea>
                  </b-form-group>
                  <b-form-group
                    id="SegnalatoriGroup"
                    horizontal
                    label="Reported by"
                    :label-cols="3"
                    label-for="segnalatore"
                    v-if="auth.isAdmin()"
                  >
                    <typeahead-person
                      :suggestions="segnalatori"
                      id="segnalatore"
                      @select="onSegnalatoreSelect"
                      @change="onSegnalatoreChange"
                      v-model="form.segnalatore.id"
                      ref="segnalatore"
                    ></typeahead-person>
                  </b-form-group>
                  <b-form-group
                    id="altriRuoliGroup"
                    horizontal
                    label="Other roles"
                    :label-cols="3"
                  >
                    <div id="altri_ruoli">
                      {{ altri_ruoli_lbl != "" ? altri_ruoli_lbl : "&nbsp;" }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="3">
                  <p>Background image</p>
                  <bg-input
                    ref="sfondoPlayer"
                    @change="onSfondoChange"
                    size="10"
                    class="my-1"
                    :customStrings="customStrings"
                    :prefill="sfondo"
                  ></bg-input>
                </b-col>
                <b-col>
                  <div class="modulo">
                    <div class="campo">
                      <campo />
                    </div>
                    <div
                      v-for="r in ruoli"
                      :key="r.id"
                      :class="'player ' + r.sigla.toLowerCase()"
                      v-on:click="selezionaRuolo(r.id)"
                      :title="r.ruolo"
                    >
                      <span
                        :class="
                          'ruolo' +
                          (form.altri_ruoli.indexOf(r.id) > -1
                            ? ' on'
                            : form.ruolo_ideale == r.id
                            ? ' ide'
                            : '')
                        "
                        >{{ r.sigla }}
                        <div
                          :class="
                            'ovale' +
                            (form.altri_ruoli.indexOf(r.id) > -1
                              ? ' bg_on'
                              : form.ruolo_ideale == r.id
                              ? ' bg_ide'
                              : '')
                          "
                        ></div
                      ></span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary" class="mr-2"
                >Save</b-button
              >
              <b-button type="reset" variant="secondary" class="mr-2"
                >Cancel</b-button
              >
              <b-button
                @click="eliminaGiocatore"
                variant="danger"
                v-if="$route.params.id && auth.isAdmin()"
                >Delete</b-button
              >
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-modal
      id="searchPlayerModal"
      ref="searchPlayerModal"
      title="Search player"
      ok-only
      ok-title="Close"
      size="xl"
    >
      <div v-if="is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px" />
        </h4>
      </div>
      <p v-if="!is_search && !players.length">No player found!</p>
      <div v-if="!is_search && players.length">
        <p>{{ players.length }} players found!</p>
        <hr />
        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden">
          <div class="row">
            <div
              v-for="player in players"
              :key="player.id"
              class="col-6"
              style="border-bottom: 1px solid #343a40; cursor: pointer"
              v-on:click="selezionaGiocatore(player)"
            >
              <div class="row">
                <div class="col-md-3 my-1 mr-1" style="text-align: center">
                  <img
                    :id="'img_' + player.wyId"
                    v-if="player.imageDataURL"
                    :src="player.imageDataURL"
                    height="110"
                  />
                </div>
                <div class="col-md-6 my-1" style="font-size: 0.85rem">
                  <h6 style="display: inline">
                    {{ player.lastName }} {{ player.firstName }}
                  </h6>
                  <br />
                  <span class="text-muted" v-if="player.birthArea"
                    ><flag
                      v-if="player.birthArea.alpha2code"
                      :country="player.birthArea.alpha2code"
                      :size="24"
                    ></flag>
                    {{ player.birthArea.name }} </span
                  ><br />
                  <span class="text-muted">{{
                    player.birthDate | formatDate
                  }}</span
                  ><br />
                  <span class="text-muted">{{ player.role.name }}</span
                  ><br />
                  <span class="text-muted" v-if="player.currentTeam"
                    ><flag
                      v-if="player.birthArea.alpha2code"
                      :country="player.currentTeam.area.alpha2code"
                      :size="24"
                    ></flag>
                    {{ player.currentTeam.officialName }} </span
                  ><br />
                </div>
                <div
                  class="col-md-2 my-1 align-self-center"
                  style="text-align: center"
                >
                  <img
                    v-if="player.currentTeam"
                    :src="player.currentTeam.imageDataURL"
                    width="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="searchPlayerSimileModal"
      ref="searchPlayerSimileModal"
      title="Similar player search"
      ok-only
      ok-title="Close"
    >
      <div v-if="is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px" />
        </h4>
      </div>
      <p v-if="!is_search && !players.length">No player found!</p>
      <div v-if="!is_search && players.length">
        <p>{{ players.length }} players found!</p>
        <hr />
        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden">
          <div
            v-for="player in players"
            :key="player.id"
            class="row"
            style="border-bottom: 1px solid #e5e5e5; cursor: pointer"
            v-on:click="selezionaGiocatoreSimile(player)"
          >
            <div class="col-md-3 my-1 mr-1" style="text-align: center">
              <img v-if="player.imageDataURL" :src="player.imageDataURL" />
            </div>
            <div class="col-md-6 my-1">
              <h5 style="display: inline">
                {{ player.lastName }} {{ player.firstName }}
              </h5>
              <br />
              <span class="text-muted" v-if="player.birthArea"
                ><flag
                  v-if="player.birthArea.alpha2code"
                  :country="player.birthArea.alpha2code"
                  :size="24"
                ></flag>
                {{ player.birthArea.name }} </span
              ><br />
              <span class="text-muted">{{ player.birthDate | formatDate }}</span
              ><br />
              <span class="text-muted">{{ player.role.name }}</span
              ><br />
              <span class="text-muted" v-if="player.currentTeam"
                ><flag
                  v-if="player.birthArea.alpha2code"
                  :country="player.currentTeam.area.alpha2code"
                  :size="24"
                ></flag>
                {{ player.currentTeam.officialName }}</span
              ><br />
            </div>
            <div
              class="col-md-2 my-1 align-self-center"
              style="text-align: center"
            >
              <img
                v-if="player.currentTeam"
                :src="player.currentTeam.imageDataURL"
                width="100"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="searchTeamModal"
      ref="searchTeamModal"
      title="Search team"
      ok-only
      ok-title="Close"
    >
      <div v-if="show_aree">
        <span>Select area: </span>
        <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
          <div v-if="aree.length == 0">
            <h4>
              Waiting...
              <img :src="loading" style="width: 150px" />
            </h4>
          </div>
          <b-row>
            <b-col
              cols="3"
              v-for="area in aree"
              style="padding: 0.2rem"
              :key="area.id"
            >
              <b-card
                class="small text-center"
                style="cursor: pointer; height: 100%"
                @click="selezionaArea(area.sigla3)"
              >
                <flag :country="area.sigla" :size="24"></flag><br />
                {{ area.paese }}
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="show_competizioni">
        <div v-if="is_search">
          <h4>
            Waiting...
            <img :src="loading" style="width: 150px" />
          </h4>
        </div>
        <div v-if="!is_search && competizioni.length == 0">
          <h5>No competition found!</h5>
        </div>
        <div v-if="competizioni.length > 0">
          <span>Select a competition: </span>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="comp in competizioni"
                :key="comp.id"
                style="padding: 0.2rem"
              >
                <b-card
                  class="small text-center"
                  style="cursor: pointer; height: 100%"
                  @click="selezionaCompetizione(comp)"
                >
                  <strong>{{ comp.name }}</strong
                  ><br />
                  <span class="font-size-xs color-rosso">{{
                    $t(comp.format)
                  }}</span
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="show_squadre">
        <div v-if="is_search">
          <h4>
            Waiting...
            <img :src="loading" style="width: 150px" />
          </h4>
        </div>
        <div v-if="!is_search && squadre.length == 0">
          <h5>No team found!</h5>
        </div>
        <div v-if="squadre.length > 0">
          <span>Select a team: </span>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="team in squadre"
                style="padding: 0.2rem"
                :key="team.id"
              >
                <b-card
                  class="small text-center py-1"
                  style="cursor: pointer; height: 100%"
                  @click="selezionaSquadra(team)"
                >
                  <img :src="team.imageDataURL" width="50" /><br />
                  <strong>{{ team.officialName }}</strong
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="loadTeamModal"
      ref="loadTeamModal"
      title="Load team"
      ok-only
      ok-title="Close"
    >
      <div v-if="show_aree">
        <span>Select area: </span>
        <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
          <div v-if="is_search">
            <h4>
              Waiting...
              <img :src="loading" style="width: 150px" />
            </h4>
          </div>
          <div v-if="!is_search && aree.length == 0">
            <h5>No area found!</h5>
          </div>
          <b-row>
            <b-col
              cols="3"
              v-for="area in aree"
              style="padding: 0.2rem"
              :key="area.id"
            >
              <b-card
                class="small text-center"
                style="cursor: pointer; height: 100%"
                @click="scegliArea(area.id)"
              >
                <flag :country="area.sigla" :size="24"></flag><br />
                {{ area.paese }}
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="show_squadre">
        <div v-if="is_search">
          <h4>
            Waiting...
            <img :src="loading" style="width: 150px" />
          </h4>
        </div>
        <div v-if="!is_search && squadre.length == 0">
          <h5>No team found!</h5>
        </div>
        <div v-if="squadre.length > 0">
          <span>Select a team: </span>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="team in squadre"
                style="padding: 0.2rem"
                :key="team.id"
              >
                <b-card
                  class="small text-center py-1"
                  style="cursor: pointer; height: 100%"
                  @click="scegliSquadra(team)"
                >
                  <img :src="team.logo_url" width="50" /><br />
                  <strong>{{ team.name }}</strong
                  ><br />
                  <span class="font-size-xs color-rosso">{{
                    team.categoria ? team.categoria.name : ""
                  }}</span
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="searchOptaPlayerModal"
      ref="searchOptaPlayerModal"
      :title="$t('searchPlayer')"
      ok-only
      ok-title="Close"
    >
      <b-input-group class="mb-2">
        <b-form-input type="text" v-model="search_opta"></b-form-input>
        <b-input-group-append>
          <b-button
            variant="secondary"
            @click="searchOptaPlayer"
            title="Search opta player"
            ><b-icon icon="search"></b-icon
          ></b-button>
        </b-input-group-append>
      </b-input-group>
      <div v-if="!start_search_opta && is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px" />
        </h4>
      </div>
      <p v-if="!start_search_opta && !is_search && !opta_players.length">
        No player found!
      </p>
      <div v-if="!start_search_opta && !is_search && opta_players.length">
        <p>{{ opta_players.length }} players found!</p>
        <hr />
        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden">
          <div class="row">
            <div
              v-for="player in opta_players"
              :key="player.id"
              class="col-6"
              style="border-bottom: 1px solid #343a40; cursor: pointer"
              v-on:click="selezionaOptaPlayer(player.id)"
            >
              <div class="row">
                <div class="col-md-6 my-1" style="font-size: 0.85rem">
                  <h6 style="display: inline">
                    {{ player.lastName }} {{ player.firstName }}
                  </h6>
                  <br />
                  <span class="text-muted">{{ player.age }} years old</span
                  ><br />
                  <span class="text-muted">{{
                    player.ruolo ? player.ruolo.ruolo : ""
                  }}</span
                  ><br />
                  <span
                    class="text-muted"
                    v-if="player.active_career && player.active_career.team"
                  >
                    {{ player.active_career.team.name }} </span
                  ><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
import PictureInput from "@/components/PictureInput.vue";
import TypeaheadCountry from "@/components/TypeaheadCountry.vue";
import TypeaheadPerson from "@/components/TypeaheadPerson.vue";
import BgInput from "@/components/BgInput.vue";
import flag from "@/components/Flag.vue";
import campo from "@/assets/images/campo.svg";
import logoteam from "@/assets/images/team.png";
import loading from "@/assets/images/loading.gif";
import logoopta from "@/assets/images/opta.png";

export default {
  data: function () {
    return {
      image_player: "",
      image_agente: "",
      image_logo: "",
      image_sfondo: "",
      players: [],
      opta_players: [],
      is_search: false,
      file_avatar: "",
      file_sfondo: "",
      file_avatar_agente: "",
      form: {
        nome: "",
        cognome: "",
        data_nascita: null,
        paese_nascita: 0,
        passaporti: [],
        altezza: 0,
        peso: 0,
        piede: "0",
        squadra: "",
        categoria: "",
        agente: {
          id: 0,
          nome: "",
          cognome: "",
          email: null,
          telefono1: null,
          telefono2: null,
          id_paese: 0,
          luogo_nascita: null,
          data_nascita: null,
          indirizzo: null,
        },
        agenzia: {
          id: 0,
          nome: "",
        },
        segnalatore: {
          id: 0,
          nome: "",
        },
        valore: null,
        stipendio: null,
        bonus: [],
        telefono1: "",
        telefono2: "",
        infortuni: [],
        giovanili: false,
        vivaio: false,
        baricentro: null,
        somatotipo: null,
        costituzione: null,
        ruolo_ideale: 0,
        sistema_ideale: 0,
        monitorare: false,
        prospettiva: false,
        prestito: false,
        segui: false,
        giocatori_simili: "",
        altri_ruoli: [],
        wyscout_id: "",
        wyscout_team_id: "",
        current_team: {},
        id_squadra: 0,
        opta_player_id: null,
      },
      paesi: [],
      agenti: [],
      agenzie: [],
      segnalatori: [],
      infortuni: [],
      ruoli: [],
      sistemi: [],
      etichette: {
        baricentro: ["Basso", "Medio", "Alto"],
        somatotipo: ["Normolineo", "Brevilineo", "Longilineo"],
        costituzione: ["Esile", "Media", "Robusta"],
      },
      errors: [],
      customStrings: {
        drag: this.$t("drag"),
        fileSize: this.$t("fileSize"),
        fileType: this.$t("fileType"),
      },
      giocatore_simile: "",
      passaporti: [],
      aree: [],
      competizioni: [],
      squadre: [],
      show_aree: false,
      show_competizioni: false,
      show_squadre: false,
      logoteam,
      loading,
      logoopta,
      start_search_opta: true,
      search_opta: "",
      osservato: null,
    };
  },
  components: {
    PictureInput,
    BgInput,
    TypeaheadCountry,
    TypeaheadPerson,
    campo,
    flag,
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      var formData = new FormData();
      formData.append("form", JSON.stringify(this.form));
      formData.append("file_avatar", this.file_avatar);
      formData.append("file_sfondo", this.file_sfondo);
      formData.append("file_avatar_agente", this.file_avatar_agente);
      var path = this.$route.params.id
        ? "/scouting/edit/giocatore/" + this.$route.params.id
        : "/scouting/add/giocatore";
      this.$http
        .post(path, formData)
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          if (this.auth.isUserEnable("opta") && response.data.opta_player_id) {
            this.$router.push({
              name: "ScoutingViewPlayer",
              params: { id: response.data.opta_player_id },
            });
          } else if (
            this.auth.isUserEnable("player_eval") &&
            response.data.giocatore_id > 0
          ) {
            this.$router.push({
              name: "ScoutingEvalPlayer",
              params: { id: response.data.giocatore_id },
            });
          } else {
            this.$router.push({ name: "ScoutingPlayers" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.errors = error.response.data;
              for (let index in this.errors) {
                this.$store.commit("msgPush", {
                  msg: this.errors[index],
                  status: 0,
                });
              }
              if (
                this.auth.isUserEnable("opta") &&
                error.response.data.opta_player_id
              ) {
                this.$router.push({
                  name: "ScoutingViewPlayer",
                  params: { id: error.response.data.opta_player_id },
                });
              } else if (
                this.auth.isUserEnable("player_eval") &&
                error.response.data.giocatore_id > 0
              ) {
                this.$router.push({
                  name: "ScoutingEvalPlayer",
                  params: { id: error.response.data.giocatore_id },
                });
              } else {
                this.$router.push({ name: "ScoutingPlayers" });
              }
            }
          }
        });
    },
    onAvatarChange() {
      //	console.log('New picture selected!')
      if (this.$refs.picturePlayer.file) {
        this.file_avatar = this.$refs.picturePlayer.file;
      } else {
        console.log("FileReader Player API not supported: use the <form>");
      }
    },
    onSfondoChange() {
      //  console.log('New picture selected!')
      if (this.$refs.sfondoPlayer.file) {
        this.file_sfondo = this.$refs.sfondoPlayer.file;
      } else {
        //    console.log('FileReader Player API not supported: use the <form>')
      }
    },
    onAvatarAgenteChange() {
      //	console.log('New picture selected!')
      if (this.$refs.pictureAgente.file) {
        this.file_avatar_agente = this.$refs.pictureAgente.file;
      } else {
        //		console.log('FileReader Player API not supported: use the <form>')
      }
    },
    onPaeseNascitaSelect(item) {
      this.form.paese_nascita = item;
    },
    onPaeseNascitaChange() {
      this.form.paese_nascita = 0;
    },
    onPaeseAgenteSelect(item) {
      this.form.agente.id_paese = item;
    },
    onPaeseAgenteChange() {
      this.form.agente.id_paese = 0;
    },
    addPassaporto: function () {
      this.form.passaporti.push({ paese_id: 0 });
    },
    removePassaporto: function (item) {
      var index = this.form.passaporti.indexOf(item);
      this.form.passaporti.splice(index, 1);
    },
    onPassaportoSelect(item, index) {
      this.form.passaporti[index].paese_id = item;
    },
    onPassaportoChange(index) {
      this.form.passaporti[index].paese_id = 0;
    },
    onAgenteSelect(id) {
      this.form.agente.id = id;
      for (var index in this.agenti) {
        var agente = this.agenti[index];
        if (agente.id == id) {
          this.form.agente.cognome = agente.cognome;
          this.form.agente.nome = agente.nome;
          this.form.agente.email = agente.email;
          this.form.agente.telefono1 = agente.telefono;
          this.form.agente.telefono2 = agente.telefono2;
          this.form.agente.luogo_nascita = agente.luogo_nascita;
          this.form.agente.data_nascita = agente.data_nascita;
          this.form.agente.indirizzo = agente.indirizzo;
          if (agente.avatar != null && agente.avatar != "") {
            this.image_agente = agente.avatar_url;
          }
          if (agente.paese) {
            this.$refs.paese_agente.setPaese(
              agente.paese.id,
              agente.paese.paese
            );
          }
          break;
        }
      }
    },
    onAgenteChange(value) {
      this.form.agente.id = 0;
      this.form.agente.cognome = value;
      this.form.agente.nome = "";
      this.form.agente.email = null;
      this.form.agente.telefono1 = null;
      this.form.agente.telefono2 = null;
      this.image_agente = "";
      this.file_avatar_agente = "";
      this.form.agente.luogo_nascita = null;
      this.form.agente.data_nascita = null;
      this.form.agente.indirizzo = null;
      this.$refs.paese_agente.setPaese(0, "");
    },
    onAgenziaSelect(id) {
      this.form.agenzia.id = id;
      for (var index in this.agenzie) {
        var agenzia = this.agenzie[index];
        if (agenzia.id == id) {
          this.form.agenzia.nome = agenzia.nome;
          break;
        }
      }
    },
    onAgenziaChange(value) {
      this.form.agenzia.id = 0;
      this.form.agenzia.nome = value;
    },
    onSegnalatoreSelect(id) {
      this.form.segnalatore.id = id;
      for (var index in this.agenzie) {
        var segnalatore = this.segnalatori[index];
        if (segnalatore.id == id) {
          this.form.segnalatore.nome = segnalatore.nome;
          break;
        }
      }
    },
    onSegnalatoreChange(value) {
      this.form.segnalatore.id = 0;
      this.form.segnalatore.nome = value;
    },
    updateImageAgente() {
      this.$refs.pictureAgente.onResize();
    },
    addBonus: function () {
      this.form.bonus.push({ valore: 0, descrizione: "" });
    },
    removeBonus: function (item) {
      var index = this.form.bonus.indexOf(item);
      this.form.bonus.splice(index, 1);
    },
    addInfortunio: function () {
      this.form.infortuni.push({ tipo: 0, data: "" });
    },
    removeInfortunio: function (item) {
      var index = this.form.infortuni.indexOf(item);
      this.form.infortuni.splice(index, 1);
    },
    selezionaRuolo: function (ruolo) {
      console.log("selezionaRuolo " + ruolo);
      if (ruolo != this.form.ruolo_ideale) {
        if (this.form.altri_ruoli.indexOf(ruolo) == -1) {
          this.form.altri_ruoli.push(ruolo);
        } else {
          this.form.altri_ruoli.splice(this.form.altri_ruoli.indexOf(ruolo), 1);
        }
      }
    },
    searchPlayer: function () {
      var query = this.form.cognome.trim().replace(/ /g, "_");
      if (query != "") {
        this.players = [];
        this.is_search = true;
        this.$refs.searchPlayerModal.show();
        if (this.form.nome.trim() != "") {
          query = query + "_" + this.form.nome.trim().replace(/ /g, "_");
        }
        this.$http.get("/ws/search/player/" + query).then((response) => {
          this.players = response.data;
          this.is_search = false;
        });
      }
    },
    searchPlayerSimile: function () {
      var query = this.giocatore_simile.trim().replace(/ /g, "_");
      if (query != "") {
        this.players = [];
        this.is_search = true;
        this.$refs.searchPlayerSimileModal.show();
        this.$http.get("/ws/search/player/" + query).then((response) => {
          this.players = response.data;
          this.is_search = false;
        });
      }
    },
    searchTeam: function () {
      this.aree = [];
      this.show_aree = true;
      this.show_competizioni = false;
      this.show_squadre = false;
      if (this.aree.length == 0) {
        this.$http.get("/ws/aree").then((response) => {
          this.aree = response.data;
        });
      }
      this.$refs.searchTeamModal.show();
    },
    selezionaArea: function (sigla) {
      this.show_aree = false;
      this.competizioni = [];
      this.is_search = true;
      this.show_competizioni = true;
      this.$http.get("/ws/get/competitions/" + sigla).then((response) => {
        this.competizioni = response.data;
        this.is_search = false;
      });
    },
    selezionaCompetizione: function (competizione) {
      this.show_competizioni = false;
      this.squadre = [];
      this.is_search = true;
      this.show_squadre = true;
      this.form.categoria = competizione.name;
      this.$http
        .get("/ws/get/teams/by/competition/" + competizione.wyId)
        .then((response) => {
          this.squadre = response.data;
          this.is_search = false;
        });
    },
    selezionaSquadra: function (squadra) {
      this.$refs.searchTeamModal.hide();
      this.players = [];
      this.is_search = true;
      this.$refs.searchPlayerModal.show();
      this.$http
        .get("/ws/get/players/by/team/" + squadra.wyId)
        .then((response) => {
          this.players = response.data;
          this.is_search = false;
        });
    },
    selezionaGiocatore: function (giocatore) {
      if (
        this.$route.params.id &&
        this.form.wyscout_id != "" &&
        this.form.wyscout_id != giocatore.wyId
      ) {
        if (!confirm("Vuoi sovrascrivere il giocatore attuale?")) {
          return;
        }
      }
      this.form.wyscout_id = giocatore.wyId;
      this.form.cognome = giocatore.lastName;
      this.form.nome = giocatore.firstName;
      this.form.data_nascita = giocatore.birthDate;
      this.form.altezza = giocatore.height;
      this.form.peso = giocatore.weight;
      this.form.piede = this.piede(giocatore.foot);

      if (giocatore.currentTeam) {
        this.form.squadra = giocatore.currentTeam.officialName;
        this.form.wyscout_team_id = giocatore.currentTeam.wyId;
        this.form.current_team = giocatore.currentTeam;
      } else {
        this.form.squadra = "";
        this.form.wyscout_team_id = "";
        this.form.current_team = {};
      }
      this.form.id_squadra = 0;

      /*  this.toDataUrl(giocatore.imageDataURL, function (myBase64) {
        console.log(myBase64); // myBase64 is the base64 string
        this.image_player = myBase64;
      }); */
      //  var base64 = this.getBase64Image(document.getElementById("img_" + giocatore.wyId));

      this.image_player = giocatore.imageDataURL;
      if (giocatore.birthArea.alpha2code) {
        for (let index in this.paesi) {
          const paese = this.paesi[index];
          if (giocatore.birthArea.alpha2code == paese.sigla) {
            this.$refs.paese_nascita.setPaese(paese.id, paese.paese);
            break;
          }
        }
      }
      this.form.passaporti = [];
      if (giocatore.passportArea.alpha2code) {
        for (let index in this.paesi) {
          const paese = this.paesi[index];
          if (giocatore.passportArea.alpha2code == paese.sigla) {
            this.addPassaporto();
            this.$nextTick(function () {
              this.$refs["passaporto"][0].setPaese(paese.id, paese.paese);
            });
            break;
          }
        }
      }
      this.$refs.searchPlayerModal.hide();

      //this.getContractPlayer(giocatore.wyId);
      //this.getTransfersPlayer(giocatore.wyId);
    },

    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.responseType = "blob";
      xhr.send();
    },

    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    },

    selezionaGiocatoreSimile: function (giocatore) {
      if (this.form.giocatori_simili != "") {
        this.form.giocatori_simili += ", ";
      }
      this.form.giocatori_simili +=
        giocatore.lastName + " " + giocatore.firstName;
      this.giocatore_simile = "";
      this.$refs.searchPlayerSimileModal.hide();
    },

    piede: function (foot) {
      if (foot == "right") {
        return "0";
      } else if (foot == "left") {
        return "1";
      } else if (foot == "both") {
        return "2";
      }
      return "0";
    },

    eliminaGiocatore: function () {
      if (confirm("Confirm the elimination of the player?")) {
        this.$http
          .get("/scouting/delete/giocatore/" + this.$route.params.id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: response.data.message,
              status: 1,
            });
            this.$router.push({ name: "ScoutingPlayers" });
          });
      }
    },

    loadSquadra: function () {
      this.aree = [];
      this.show_aree = true;
      this.show_squadre = false;
      this.is_search = true;
      if (this.aree.length == 0) {
        this.$http.get("/paesi/squadre").then((response) => {
          this.aree = response.data;
          this.is_search = false;
        });
      }
      this.$refs.loadTeamModal.show();
    },
    scegliArea: function (id) {
      this.squadre = [];
      this.show_aree = false;
      this.is_search = true;
      this.show_squadre = true;
      this.$http.get("/squadre/paese/" + id).then((response) => {
        this.squadre = response.data;
        this.is_search = false;
      });
    },
    scegliSquadra: function (squadra) {
      this.form.id_squadra = squadra.id;
      this.form.squadra = squadra.officialName;
      this.form.wyscout_team_id = squadra.wyscout_id;
      this.form.current_team = squadra;
      if (squadra.categoria) {
        this.form.categoria = squadra.categoria.name;
      }
      this.$refs.loadTeamModal.hide();
    },
    openSearchOptaPlayer: function () {
      this.start_search_opta = true;
      this.search_opta = this.form.cognome.trim();
      this.$refs.searchOptaPlayerModal.show();
    },
    searchOptaPlayer: function () {
      if (this.search_opta != "") {
        this.opta_players = [];
        this.is_search = true;
        this.$http
          .get("/opta/search/player/" + this.search_opta)
          .then((response) => {
            this.opta_players = response.data;
            this.start_search_opta = false;
            this.is_search = false;
          });
      }
    },
    selezionaOptaPlayer: function (player_id) {
      this.$refs.searchOptaPlayerModal.hide();
      this.form.opta_player_id = player_id;
    },

    getContractPlayer: function (wyId) {
      this.$http.get("/ws/get/player/contract/" + wyId).then((response) => {
        console.log(response);
      });
    },

    getTransfersPlayer: function (wyId) {
      this.$http.get("/ws/get/player/transfers/" + wyId).then((response) => {
        console.log(response);
      });
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    avatar: function () {
      if (this.image_player != "") {
        return this.image_player;
      }
      return "";
    },
    sfondo: function () {
      if (this.image_sfondo != "") {
        return this.image_sfondo;
      }
      return "";
    },
    avatar_agente: function () {
      if (this.image_agente != "") {
        return this.image_agente;
      }
      return "";
    },

    altri_ruoli_lbl: function () {
      var label = "";
      for (var index in this.form.altri_ruoli) {
        var id_ruolo = this.form.altri_ruoli[index];
        if (id_ruolo > 0) {
          label =
            label + this.ruoli.find((item) => item.id == id_ruolo).ruolo + ", ";
        }
      }
      if (label.length > 0) {
        label = label.substr(0, label.length - 2);
      }
      return label;
    },

    logo_squadra: function () {
      if (this.form.current_team) {
        if (this.form.current_team.logo_url) {
          return this.form.current_team.logo_url;
        }
        if (this.form.current_team.imageDataURL) {
          return this.form.current_team.imageDataURL;
        }
      }
      return this.logoteam;
    },
  },

  created: function () {
    this.$http.get("/paesi").then((response) => {
      this.paesi = response.data;
    });

    this.$http.get("/infortuni").then((response) => {
      this.infortuni = response.data;
    });

    this.$http.get("/ruoli").then((response) => {
      this.ruoli = response.data;
    });

    this.$http.get("/sistemi").then((response) => {
      this.sistemi = response.data;
    });

    this.$http.get("/agenti").then((response) => {
      this.agenti = response.data;
    });

    this.$http.get("/agenzie").then((response) => {
      this.agenzie = response.data;
    });

    this.$http.get("/segnalatori").then((response) => {
      this.segnalatori = response.data;
    });

    if (this.$route.params.ln) {
      this.form.cognome = this.$route.params.ln;
    }

    if (this.$route.params.fn) {
      this.form.nome = this.$route.params.fn;
    }

    if (this.$route.params.id) {
      this.$http
        .get("/scouting/anagrafica/giocatore/" + this.$route.params.id)
        .then((response) => {
          var osservato = response.data;
          this.osservato = osservato;
          if (!osservato.id) {
            this.$store.commit("msgPush", {
              msg: "Player not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingPlayers" });
          }
          if (
            osservato.giocatore.avatar != null &&
            osservato.giocatore.avatar != ""
          ) {
            this.image_player = osservato.giocatore.avatar_url;
          }
          if (
            osservato.giocatore.sfondo != null &&
            osservato.giocatore.sfondo != ""
          ) {
            this.image_sfondo = osservato.giocatore.sfondo_url;
          }
          this.form.nome = osservato.giocatore.nome;
          this.form.cognome = osservato.giocatore.cognome;
          this.form.data_nascita = osservato.giocatore.data_nascita;
          if (osservato.giocatore.paese_nascita) {
            this.$refs.paese_nascita.setPaese(
              osservato.giocatore.paese_nascita.id,
              osservato.giocatore.paese_nascita.paese
            );
          }

          if (osservato.giocatore.passaporti) {
            for (var index in osservato.giocatore.passaporti) {
              var paese = osservato.giocatore.passaporti[index];
              this.passaporti.push(paese);
              this.addPassaporto();
            }
            this.$nextTick(function () {
              for (var index in this.passaporti) {
                var paese = this.passaporti[index];
                this.$refs.passaporto[index].setPaese(paese.id, paese.paese);
              }
            });
          }
          this.form.altezza = osservato.giocatore.altezza;
          this.form.peso = osservato.giocatore.peso;
          this.form.piede = osservato.giocatore.piede;
          this.form.squadra = osservato.giocatore.squadra_militante
            ? osservato.giocatore.squadra_militante.officialName
            : "";
          this.form.categoria = osservato.giocatore.categoria;
          this.form.valore = osservato.giocatore.valore;
          this.form.stipendio = osservato.giocatore.stipendio_lordo;
          this.form.telefono1 = osservato.giocatore.telefono;
          this.form.telefono2 = osservato.giocatore.telefono2;

          if (osservato.giocatore.cresciuto_giovanili) {
            this.form.giovanili = true;
          }
          if (osservato.giocatore.cresciuto_vivaio_italiano) {
            this.form.vivaio = true;
          }

          this.form.baricentro = osservato.giocatore.baricentro;
          this.form.somatotipo = osservato.giocatore.somatotipo;
          this.form.costituzione = osservato.giocatore.costituzione;

          if (osservato.da_monitorare) {
            this.form.monitorare = true;
          }
          if (osservato.in_prestito) {
            this.form.prestito = true;
          }
          if (osservato.di_prospettiva) {
            this.form.prospettiva = true;
          }
          if (osservato.follower_count) {
            this.form.segui = true;
          }

          if (
            osservato.giocatore.ruolo_ideale &&
            osservato.giocatore.ruolo_ideale.length > 0
          ) {
            this.form.ruolo_ideale = osservato.giocatore.ruolo_ideale[0].id;
          }
          if (osservato.giocatore.altri_ruoli) {
            for (var index in osservato.giocatore.altri_ruoli) {
              this.form.altri_ruoli.push(
                osservato.giocatore.altri_ruoli[index].id
              );
            }
          }
          this.form.sistema_ideale = osservato.giocatore.sistema_gioco_id;
          if (osservato.giocatore.infortuni) {
            for (var index in osservato.giocatore.infortuni) {
              this.form.infortuni.push({
                tipo: osservato.giocatore.infortuni[index].id,
                data: osservato.giocatore.infortuni[index].pivot.il,
              });
            }
          }
          if (osservato.giocatore.bonus) {
            for (var index in osservato.giocatore.bonus) {
              this.form.bonus.push({
                valore: osservato.giocatore.bonus[index].valore,
                descrizione: osservato.giocatore.bonus[index].descrizione,
              });
            }
          }
          if (osservato.giocatore.agente) {
            this.$refs.agente.setNome(
              osservato.giocatore.agente.id,
              osservato.giocatore.agente.cognome
            );
          }
          if (osservato.giocatore.agenzia) {
            this.$refs.agenzia.setNome(
              osservato.giocatore.agenzia.id,
              osservato.giocatore.agenzia.nome
            );
          }
          if (osservato.segnalatore) {
            this.$refs.segnalatore.setNome(
              osservato.segnalatore.id,
              osservato.segnalatore.nome
            );
          }
          this.form.giocatori_simili = osservato.giocatore.giocatori_simili;
          if (osservato.giocatore.wyscout_id) {
            this.form.wyscout_id = osservato.giocatore.wyscout_id;
          }
          if (osservato.giocatore.squadra_militante) {
            this.form.id_squadra = osservato.giocatore.squadra_militante.id;
            this.form.wyscout_team_id =
              osservato.giocatore.squadra_militante.wyscout_id;
            this.image_logo = osservato.giocatore.squadra_militante.logo_url;
          }
          if (osservato.giocatore.opta_player) {
            this.form.opta_player_id = osservato.giocatore.opta_player.id;
          }
        });
    }
  },
  mounted() {},

  watch: {
    "form.ruolo_ideale": function (ruolo) {
      if (this.form.altri_ruoli.indexOf(ruolo) > -1) {
        this.form.altri_ruoli.splice(this.form.altri_ruoli.indexOf(ruolo), 1);
      }
    },
    form: {
      handler: function () {
        this.errors = [];
      },
      deep: true,
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
